.bioPage {
  /* position: absolute; */
  /* display: column; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.bio {
  /* margin-left: 250px; Same as the width of the sidebar */
  /* margin-top: 20px; */
  /* position: relative; */
  /* margin: 0 auto; */
  /* padding: 0px; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%; /* Take up the available width */
  max-width: 1500px;
  /* align-items: center; */
  /* margin-top: 60px; */
  /* min-width: 0; */
  /* justify-content: space-between; */
}

.homepagetxt {
  flex: 1;
  /* justify-content: center; */
  /* align-items: left; */
  /* padding: 0px; */
  margin-right: 50px;
  font-size: 1.5cm;
  font-family: inherit;
  /* clear: right; */
  height: auto;
  text-align: left;
}

.homepageimg {
  /* flex: 1; */
  /* text-align: right; */
  /* padding: 0px; */
  /* margin-right: 30px; */
  margin-top: 60px;
  /* display: block; */
}

.resume {
  /* position: absolute; */
  /* display: flex; */
  /* margin-top: ; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex: 2; */
  text-align: center;
  /* position: relative; */
  margin-bottom: 20px;
  stroke: rgb(15 38 31); /*  //for the icon */
  /* width: fit-content; */
}

.resume a {
  text-decoration: none;
  align-items: center;
  font-family: inherit;
  font-size: 0.9cm;
  color: inherit;
  display: inline-flex;
}

.arrow {
  stroke: inherit;
}
.me{
  height:600px;
  width:auto;
}

/* .resume:hover .arrow {
  stroke: #8f8d8d;
} */
.resume a:hover {
  color: #8f8d8d;
  stroke: #8f8d8d;
}
/* TODO:stack image on mobile and small screens, decrease font size  */
@media screen and (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .bio {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
    /* padding: 0; */
    /* margin-bottom:15px; */
  }
  .bioPage{
    margin-top:-70px;
    margin-left:0;
  }

  .homepagetxt {
    margin-right:0;
    text-align:center;
    font-size: 0.8cm;
  font-family: inherit;
  /* margin-bottom:-5px; */
  }
.resume{
  margin-bottom:-25px
}
  .me{
    height:auto;
    /* max-height:500px; */
    width:100%;
    max-width:300px;

    /* margin-left:0; */
  }


  .homepageimg {
    order:-1;
    margin-bottom: -20px; 
    /* Remove right margin for narrow screens */
    margin-top: 10px;

    /* align-items: center; */
    /* align-content: center; */
    /* padding:0; */
    /* margin-left:0; */
    /* Add space between text and image */
  }

}
