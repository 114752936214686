.footer {
  text-align: center;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.sm-icons {
  margin-bottom: 10px;
}

.icon {
  margin: 0 10px;
  font-size: 24px;
  /* transition: color 0.3s ease; */
  /* color: black; */
  width: 30px;
  height: 30px;
}

.icon:hover {
  stroke: #8f8d8d; /* Change color on hover (example: Twitter's blue) */
}
/* #cecece */
