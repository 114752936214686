/* .description {
  font-size: 0.65cm;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
} */

/* .text {
  margin-left: 1.5cm;
} */
.project-item {
  padding: 10px;
  padding-left: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  /* background-color: rgba(222, 220, 255, 0.2); */
  /* background-color: rgba(156, 178, 158, 0.2); */
  background-color: rgb(194, 192, 165);
  border: 2px solid transparent;
  /* background-color: rgba(191, 171, 204, 0.2); */

  /* position: relative; */
  /* height: auto; */
  /* margin-top: -10px; */
}
/* for the project-item div to wrap entire contents */
.project-item::after {
  content: "";
  display: table;
  clear: both;
}

.project-item:hover {
  /* border: 0.05cm solid black; */
  /* height: auto; */
  border-radius: 10px;
  /* background-color: #c7c7c7; */
  /* background-color: rgba(195, 165, 92, 0.2); */
  /* background-color: rgb(50, 78, 42, 05); */
  /* background-color: rgb(175, 185, 161); */
  border: 2px solid rgb(15 38 31);
  cursor: pointer;
  /* background-color: #8f8d8d; */
}

.projectList p {
  font-size: 0.5cm;
}

.skill {
  /* position */
  display: block;
  font-size: 0.5cm;
  list-style-type: none;
  /* margin-left; */
}
.skill p,
.skill li {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  /* margin-top: 5px; */
  width: fit-content;
  /* border: 1px solid; */
  /* border-color: black; */
  border-radius: 10px;
  /* background-color: #c7c7c7; */
  /* background-color: rgba(156, 178, 158, 0.5); */
  /* background-color: rgb(15, 38, 31); */
  background-color: rgb(23, 59, 48);
  color: rgb(229, 224, 210);
  padding: 5px;
}

/* TODO:stack image on mobile and small screens, decrease font size  */
@media screen and (max-width: 768px) {
  .projectList p {
    font-size: 0.5cm;
  }

  .projects-content{
    margin-top:-40px;
  }
  .project-text h1 {
    font-size: 0.7cm;
    margin-bottom:-15px;
    margin-top:5px;
    /* line-height: ; */
  }
  .skill{
    font-size: 0.4cm;
    margin-top:-10px;
  }

  body{
    margin:0;
    padding:0;
  }
  
}