.description {
  font-size: 0.65cm;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
}

.item {
  padding: 15px;
  /* margin-top: -10px; */
  background-color: #c7c7c7;
  display: flex;
  /* justify-content: center; */
  margin: auto;
  /* display: block; */
  align-items: center;
  border-radius: 10px;
}
/* .sweaterImg {
  margin: auto;
  vertical-align: center;
} */
.fashion-text {
  padding: 15px;
  border-radius: 10px;
  /* background-color: #c7c7c7; */
}
/*  */

.fashion-text p {
  font-size: 0.5cm;
}
