.user-flow,
.calendar {
  display: flex;
  flex-wrap: wrap;
  /* padding: 30px; */
  /* display: grid; */
}
img {
  /* max-width: 100%; */
  padding: 20px;
  /* max-height: 300; */
}

