.masonry-grid {
  display: flex;
  /* margin-left: -30px; gutter size */
  width: auto;
  /* padding: 15px; */
}

.masonry-grid-col {
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

.masonry-grid_col > div {
  margin-bottom: 30px;
}

.description {
  font-size: 0.65cm;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
}
