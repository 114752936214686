/* css for project pages */
.projectContent {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 50px;
}

.projectContent p {
  font-size: 0.65cm;
}
.projectToDo {
  font-size: 0.5cm;
}

.skills p {
  font-size: 0.55cm;
}
.githubRepo {
  /* list-style-type: none; */
  /* text-decoration: none; */
  align-items: center;
  margin-top: 20px;
  /* font-family: inherit; */
  background-color: rgb(23, 59, 48);
  /* color: rgb(229, 224, 210); */
  width: fit-content;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}
.githubRepo a {
  stroke: rgb(229, 224, 210);
  color: rgb(229, 224, 210);
  text-decoration: none;
  display: inline-flex;
}
.logo {
  stroke: inherit;
  width: 15px;
  height: 15px;
}
