.App {
  text-align: center;
}
body {
  /* background: rgb(223 224 225); */
  /* 183 183 204 */
  /* background-color: rgb(192, 180, 144); */
  background-color: rgb(229, 224, 210);

  /* background: #a5e5db; */
  height: 100vh;
}

.content {
  /* margin-left: 250px; Same as the width of the sidebar */
  /* margin-top: 60px; */
  /* padding: 0px; */
  margin-left: 60px;
  margin-right: 60px;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@media screen and (max-width: 768px) {
  .content {
    /* margin-left: 250px; Same as the width of the sidebar */
    /* margin-top: -70px; */
    /* padding: 0px; */
    margin:40px;
    /* margin:auto; */
  }
}
/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
    /* margin-left: 0; */
  }
  .sidenav a {
    font-size: 18px;
  }
}
