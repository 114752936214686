/* Navbar container */
.navbar {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
  /* background-color: #333; */
  /* color: white; */
  font-family: inherit;
  font-size: 25px;
  text-decoration: none;
  position: relative;
  /* overflow: hidden; */
}

/* Logo/Brand Name */
.name {
  margin: 0;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.25cm;
}

/* Navbar links container */
.pages {
  text-decoration: none;

  /* list-style: none; */
  display: flex;
  margin: 0;
  padding: 0;
  gap: 20px;
  /* overflow: hidden; */
}

/* Links */
.navItem,
.dropItem {
  /* color: white; */
  position: relative;
  text-decoration: none;

  padding: 8px 16px;
  transition: background-color 0.3s ease;
}
.dropItem {
  /* margin-top: 5px;    */
  padding: 5px;
  position: relative;
}
/* Active link */
/* .active {
  background-color: #555;
  border-radius: 4px;
} */
/* add activeClassName="active" to use this*/

/* Hover effect */
.navItem:hover,
.dropItem:hover,
.dropdown-parent:hover .downIcon path {
  color: #8f8d8d;
  /* color: rgba(195, 165, 92, 0.2); */
  /* background-color: rgb(50, 78, 42, 05); */
  /* color: white; */
  /* border-radius: 10px; */

  /* color: black; */
}

/* .dropdown { */
/* float: left;
  overflow: hidden; */
/* content: center; */
/* flex-direction: column; */
/* justify-content: center; */
/* align-items: flex-start; */
/* position: relative; */
/* } */
/* .dropdown {
  position: relative;
} */
.dropdown-parent {
  position: relative;
  display: flex;
  /* align-items: center; */
  /* border: none;
  position: relative;
  outline: none;
  align-items: center;
  display: flex; */

  /* padding: 14px 16px; */
  /* text-decoration: none;
  background-color: inherit; */
  /* font-family: inherit; Important for vertical align on mobile phones */
  /* margin: 0; Important for vertical align on mobile phones */
}
.dropdown i {
  margin-left: 10px;
}
/* .dropdown:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
} */
.dropdown-items {
  display: none;
  position: absolute;
  /* flex-direction: column; */
  /* min-width: 160px; */
  text-decoration: none;
  z-index: 10;
  font-size: 0.55cm;
  text-align: left;
  margin-left: 10px;
  /* left: 0; */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* background-color: rgb(223 224 225); */
  /* background-color: rgb(204, 195, 166); */
  background-color: rgb(229, 224, 210);
  border-radius: 10px;
  /* top: 100%; */
}
.dropdown-items.dropItem {
  /* float: none; */
  /* color: white; */
  /* margin: 10px; */
  /* padding: 60px; */
  text-decoration: none;

  display: block;
  /* text-align: left; */
}
.downIcon {
  padding: 0px;
  height: 30px;
  width: 30px;
}
/* .dropdown-parent:hover .downIcon path {
  color: #8f8d8d;
} */
.dropdown:hover .dropdown-items {
  display: flex;
  flex-direction: column;

  text-decoration: none;
  /* justify-content: center; */
  /* align-items: flex-start; */
}
/* TODO: smaller screens, stack earlier */
/* Responsive: Stack navbar links on smaller screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    font-size: 20px;
  }
  .home {
    position: center;
    content: center;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
  }
}
